import React, { useState } from 'react';

import './BottomMenu.scss';
import sendEvent from '../../../helpers/tracking';
import { getString } from '../../../helpers/styles';
import Transition from '../../Transition/Transition.jsx';
import { getWeekContentLink } from '../../../helpers/userWeek';
import { isEnded, defaultCoursesPage } from '../../../helpers/user';
import { pregnancyWeekList, babyWeekList } from '../../../helpers/userWeek';

import Courses from '../../../shared/assets/icons/redesign/courses.svg';
import Magazine from '../../../shared/assets/icons/redesign/magazine.svg';
import Community from '../../../shared/assets/icons/redesign/community.svg';
import Dashboard from '../../../shared/assets/icons/redesign/dashboard.svg';
import Close from '../../../shared/assets/icons/redesign/bottom-menu-close.svg';
import Calendar from '../../../shared/assets/icons/redesign/bottom-menu-calendar.svg';

export default function BottomMenu({ user, path, NextLink }) {
  const [calendarMode, setCalendarMode] = useState(false);
  const [calendarTab, setCalendarTab] = useState('zwanger');

  const weekContentLink = getWeekContentLink(user);

  return (
    <div className="bottomMenu">
      <Transition on={calendarMode}>
        <div className="bottomMenu-calendar">
          <div className="bottomMenu-calendar-tabs">
            <div
              className={getString([
                {
                  value: 'bottomMenu-calendar-tab',
                  condition: true
                },
                {
                  value: 'bottomMenu-calendar-tab--active',
                  condition: calendarTab === 'zwanger'
                }
              ])}
              onClick={() => setCalendarTab('zwanger')}
            >
              Zwanger
            </div>

            <div className="bottomMenu-calendar-tab-divider" />

            <div
              className={getString([
                {
                  value: 'bottomMenu-calendar-tab',
                  condition: true
                },
                {
                  value: 'bottomMenu-calendar-tab--active',
                  condition: calendarTab === 'baby'
                }
              ])}
              onClick={() => setCalendarTab('baby')}
            >
              Baby
            </div>
          </div>

          <ul className="bottomMenu-calendar-week-list">
            {(calendarTab === 'zwanger' ? pregnancyWeekList : babyWeekList).map(
              (item, idx) => (
                <NextLink href={item.link} key={idx} prefetch={false}>
                  <li
                    className="bottomMenu-calendar-week-list-item"
                    onClick={() => setCalendarMode(false)}
                  >
                    <Calendar />
                    <p className="bottomMenu-calendar-week-list-item-text">
                      {item.number}
                      <span> {item.label.toLowerCase()}</span>
                    </p>
                  </li>
                </NextLink>
              )
            )}
          </ul>
        </div>
      </Transition>

      <ul className="bottomMenu-list">
        <li
          className={getString([
            {
              value: 'bottomMenu-list-item',
              condition: true
            },
            {
              value: 'bottomMenu-list-item--active',
              condition: path === '/'
            }
          ])}
          onClick={() => {
            sendEvent('footer-mobile', 'footer-mobile-feed');

            location.href = '/';
          }}
        >
          <Magazine className="bottomMenu-list-item-icon" />
          <p className="bottomMenu-list-item-label">Feed</p>
        </li>

        {!isEnded(user) && (
          <>
            {weekContentLink ? (
              <NextLink href={weekContentLink} prefetch={false}>
                <li
                  className="bottomMenu-list-item"
                  onClick={() => {
                    setCalendarMode(false);

                    sendEvent('footer-mobile', 'footer-mobile-mijn-week');
                  }}
                >
                  <Calendar className="bottomMenu-list-item-icon" />
                  <p className="bottomMenu-list-item-label">Mijn week</p>
                </li>
              </NextLink>
            ) : (
              <li
                onClick={() => {
                  setCalendarMode(!calendarMode);

                  sendEvent('footer-mobile', 'footer-mobile-mijn-week');
                }}
                className="bottomMenu-list-item"
              >
                {calendarMode ? (
                  <Close className="bottomMenu-close-icon" />
                ) : (
                  <Calendar className="bottomMenu-list-item-icon" />
                )}
                <p className="bottomMenu-list-item-label">Mijn week</p>
              </li>
            )}
          </>
        )}

        <li
          className={getString([
            {
              value: 'bottomMenu-list-item',
              condition: true
            },
            {
              value: 'bottomMenu-list-item--active',
              condition: path.startsWith('/zwangerschapscursus')
            }
          ])}
          onClick={() => {
            sendEvent('footer-mobile', 'footer-mobile-cursussen');

            location.href = defaultCoursesPage(user);
          }}
        >
          <Courses style={{ height: '25px' }} />
          <p className="bottomMenu-list-item-label">Cursusportaal</p>
        </li>

        <li
          className={getString([
            {
              value: 'bottomMenu-list-item',
              condition: true
            },
            {
              value: 'bottomMenu-list-item--active',
              condition: path === '/community/test'
            }
          ])}
          onClick={() => {
            sendEvent('footer-mobile', 'footer-mobile-community');

            location.href = '/community/onboarding?groupXuid=null';
          }}
        >
          <Community
            className="
              bottomMenu-list-item-icon
              bottomMenu-list-item-icon-thin"
          />
          <p className="bottomMenu-list-item-label">Community</p>
        </li>

        <li
          className={getString([
            {
              value: 'bottomMenu-list-item',
              condition: true
            },
            {
              value: 'bottomMenu-list-item--active',
              condition: path === '/dashboard'
            }
          ])}
          onClick={() => {
            sendEvent('footer-mobile', 'footer-mobile-dash');

            location.href = '/dashboard';
          }}
        >
          {user.username ? (
            <img
              className="bottomMenu-user-image"
              src={user.profilePicture}
              alt="user-image"
            />
          ) : (
            <Dashboard className="bottomMenu-list-item-icon" />
          )}
          <p className="bottomMenu-list-item-label">Dashboard</p>
        </li>
      </ul>
    </div>
  );
}
